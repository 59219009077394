import { Button, Select, Typography, Option, Dialog } from "@material-tailwind/react";
import { AppRoles, ConvertUnixTimestampToDate, IsGuidEmpty, ResponsiveWidthProfile } from "../../../app/common";
import { TableWithButton } from "../../../UIComponents/Tables/uiTableWithButton";
import { TableWithStripedRows } from "../../../UIComponents/Tables/uiTable";
import { useTranslation } from "react-i18next";
import { GetDirectoryMembersResponse, GetHomeDirectoryResponse, LicensesData } from "../../Directories/directoryModel";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { AssignLicenseRequest, GetLicenseResponse } from "../licensesModel";
import { GetDirectoryMembers, GetDirectoryMembersForLicenses } from "../../Directories/directorySlice";
import { GetLicensesByDirectory, GetViewLicensesByDirectory, GetViewLicensesBySubscription, GetViewLicensesByUser, PostAssignLicense, PostRevokeLicense, resetStatusCode, setStatusCodeToNone } from "../licensesSlice";
import SuccessCard from "../../CommonPages/successCard";
import InfoComponent from "../../../UIComponents/Info/infoComponent";

export function Licenses({ directorySliceData, licensesStripeData }: LicensesData) {
    const { t } = useTranslation();
    const viewLicenseDirectoryRef = useRef("");
    const viewLicenseSubscriptionRef = useRef("");
    const directoryRef = useRef("");
    const licenseRef = useRef("");
    const userRef = useRef("");
    const licenseToRevoke = useRef<GetLicenseResponse>();
    const [openDialog, setOpenDialog] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [success, setSuccess] = useState(false);
    const [messsage, setMesssage] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const [licensesByDirectory, setLicensesByDirectory] = useState<GetLicenseResponse[]>([]);
    const [licensesByUser, setLicensesByUser] = useState<GetLicenseResponse[]>([]);
    const [directoryMembers, setDirectoryMembers] = useState<GetDirectoryMembersResponse[]>([]);
    const [directoryErrorStatus, setDirectoryErrorStatus] = useState(false);
    const [licenseErrorStatus, setLicenseErrorStatus] = useState(false);
    const [userErrorStatus, setUserErrorStatus] = useState(false);

    const TABLE_HEAD = [
        t("Subscription"),
        t("AssignedUser"),
        t("ValidUntil"),
        t("LicenseType"),
        t("Revoke")
    ];
    const TABLE_HEAD_2 = [
        t("Subscription"),
        t("DirectoryOf"),
        t("ValidUntil"),
        t("LicenseType"),
    ];

    function getUserEmailById(userId: string) {
        let user = directorySliceData.directoryData.directoryMembersDataForLicenses.find(member => member.userId === userId);
        if (user?.email) { return user.email; }
        else { return ''; }
    }

    function getDirectoryEmailById(directoryId: string) {
        let directory = directorySliceData.directoryData.directoryData.find(directory => directory.directoryId === directoryId);
        if (directory?.directoryMail) { return directory.directoryMail; }
        else { return ''; }
    }

    const handleOpenDialog = () => { setOpenDialog(!openDialog); }

    const onViewLicenseDirectoryChange = (directory: any) => {
        viewLicenseDirectoryRef.current = directory;
        viewLicenseSubscriptionRef.current = "";
        setHasData(false);
        onViewClick();
    }

    const onViewLicenseSubscriptionChange = (subscription: any) => {
        viewLicenseDirectoryRef.current = "";
        viewLicenseSubscriptionRef.current = subscription;
        setHasData(false);
        onViewClick();
    }

    const onDirectoryChange = (choosenDirectory: any) => {
        directoryRef.current = choosenDirectory;
        if (directoryRef.current !== "") {
            const directoryResponse: GetHomeDirectoryResponse = {
                directoryId: directoryRef.current,
            };
            dispatch(GetLicensesByDirectory(directoryRef.current));
            dispatch(GetDirectoryMembers(directoryResponse));
            setDirectoryErrorStatus(false);
        }
        licenseRef.current = "";
        userRef.current = "";
        setLicenseErrorStatus(false);
        setUserErrorStatus(false);
    }

    const onLicenseChange = (choosenLicense: any) => {
        licenseRef.current = choosenLicense;
        setLicenseErrorStatus(false);
    }

    const onUserChange = (choosenUser: any) => {
        userRef.current = choosenUser;
        setUserErrorStatus(false);
    }

    function onViewClick(): void {
        if (viewLicenseDirectoryRef.current !== "") {
            const directory: GetHomeDirectoryResponse = {
                directoryId: viewLicenseDirectoryRef.current
            }
            dispatch(GetViewLicensesByDirectory(viewLicenseDirectoryRef.current));
            dispatch(GetDirectoryMembersForLicenses(directory));
            setHasData(true);
        }
        else if (viewLicenseSubscriptionRef.current !== "") {
            const directory: GetHomeDirectoryResponse = {
                directoryId: directorySliceData.directoryData.homeDirectoryData.directoryId
            }
            dispatch(GetViewLicensesBySubscription(viewLicenseSubscriptionRef.current));
            dispatch(GetDirectoryMembersForLicenses(directory));
            setHasData(true);
        }
        else {
            setMesssage(t("SelectaDirectoryOrSubscription"));
            setHasData(false);
            handleOpenDialog();
        }
    }

    function onRevokeClick(param: string): void {
        if (viewLicenseDirectoryRef.current) {
            licenseToRevoke.current = licensesStripeData.licensesData.viewLicensesByDirectory.find(u => u.licenseId === param);
        }
        else if (viewLicenseSubscriptionRef.current) {
            licenseToRevoke.current = licensesStripeData.licensesData.viewLicensesBySubscription.find(u => u.assignedUserId === param);
        }
        if (licenseToRevoke.current) {
            if (IsGuidEmpty(licenseToRevoke.current.assignedUserId)) {
                setMesssage(t("NoLicensesForSelectedUser"));
                setSuccess(false);
                handleOpenDialog();
                return;
            }
            dispatch(PostRevokeLicense(licenseToRevoke.current.licenseId));
            setMesssage(t("LicenseRevoked"));
            setSuccess(true);
        } else {
            setMesssage(t("NoLicensesForSelectedUser"));
            setSuccess(false);
        }
    }

    function onAssignClick(): void {
        if (Boolean(directoryRef.current) && Boolean(licenseRef.current) && Boolean(userRef.current)) {
            const licenseToAssign: AssignLicenseRequest = {
                directoryId: directoryRef.current,
                licenseId: licenseRef.current,
                userToAssignId: userRef.current,
            };
            dispatch(PostAssignLicense(licenseToAssign));
            setMesssage(t("LicenseAssigned"));
            setSuccess(true);
        } else {
            if (!Boolean(directoryRef.current)) {
                setDirectoryErrorStatus(true);
            }
            if (!Boolean(licenseRef.current)) {
                setLicenseErrorStatus(true);
            }
            if (!Boolean(userRef.current)) {
                setUserErrorStatus(true);
            }
        }
    }

    function resetAllValues() {
        directoryRef.current = "";
        licenseRef.current = "";
        userRef.current = "";
        setDirectoryErrorStatus(false);
        setLicenseErrorStatus(false);
        setUserErrorStatus(false);
    }

    useEffect(() => {
        if (licensesStripeData.licensesData.viewLicensesByDirectory.length > 0 && licensesStripeData.licensesData.viewLicensesByDirectory[0].licenseId !== "") {
            setLicensesByDirectory(licensesStripeData.licensesData.viewLicensesByDirectory);
        }
        else {
            setLicensesByDirectory([]);
        }
    }, [licensesStripeData.licensesData]);

    useEffect(() => {
        if (licensesStripeData.licensesData.viewLicensesByUser.length > 0 && licensesStripeData.licensesData.viewLicensesByUser[0].licenseId !== "") {
            setLicensesByUser(licensesStripeData.licensesData.viewLicensesByUser);
        }
        else {
            setLicensesByUser([]);
        }
    }, [licensesStripeData.licensesData]);

    useEffect(() => {
        let usersToSelect: GetDirectoryMembersResponse[] = [];

        directorySliceData.directoryData.directoryMembersData.forEach(member => {
            if (member.directoryId === directoryRef.current) {
                usersToSelect.push(member);
            }
        })
        if (usersToSelect.length > 0) {
            setDirectoryMembers(usersToSelect);
        }
    }, [directorySliceData.directoryData.directoryMembersData])

    useEffect(() => {
        if (licensesStripeData.statusValues.statusCode === '200') {
            setSuccess(true);
            handleOpenDialog();
            dispatch(setStatusCodeToNone());
            if (viewLicenseDirectoryRef.current !== "" || viewLicenseSubscriptionRef.current !== "") {
                onViewClick();
            }
            dispatch(GetViewLicensesByUser());
        } else if (licensesStripeData.statusValues.statusCode !== '') {
            setMesssage(licensesStripeData.statusValues.error);
            setSuccess(false);
            handleOpenDialog();
        }
        resetAllValues();
        dispatch(resetStatusCode());
    }, [licensesStripeData.statusValues.statusCode]);

    if (licensesStripeData.statusValues.isLoading) {
        return LoadingPage(licensesStripeData.statusValues.isLoading);
    }

    return (
        <div id="licenses" className={"scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s pb-10 " + ResponsiveWidthProfile}>
            <div className="2xl:h-[30px] rounded-t-[5px] bg-#FF6104-20">
                <Typography variant="lead" className="uppercase font-semibold px-10">
                    {t("Licenses")}
                </Typography>
            </div>
            {/* Inside Licenses*/}

            <div className=" space-y-10 sm:flex-col gap-y-10 px-10">
                <div className="flex flex-col md:flex-row gap-3 md:items-center">
                    <div className="flex flex-row items-center gap-2">
                        <Typography variant="paragraph" className="uppercase text-[16px] font-semibold">{t("ChooseLicenses")}:</Typography>
                        <InfoComponent text={"ChooseLicensesInfo"} />
                    </div>
                    {/* Inside View licenses by:*/}
                    <div className="md:flex md:flex-row md:gap-x-10 sm:gap-y-10 sm:flex-col ">
                        <div>
                            <div className="flex md:flex-row gap-5 flex-col md:items-center">
                                <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                    <Select id="SelectDirectoryLicensesView" label={t("SelectDirectory")} className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded" color="orange" onChange={(choice) => onViewLicenseDirectoryChange(choice)} value={viewLicenseDirectoryRef.current} defaultValue={""}>
                                        {directorySliceData.directoryData.directoryData.map((directory, index) => {
                                            if (directory.role !== AppRoles.Member) {
                                                return (
                                                    <Option id={index.toString()} key={directory.directoryId + index} value={directory.directoryId}>{directory.directoryMail}</Option>
                                                );
                                            }
                                            return <div key={index}></div>;
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="flex flex-col gap-3 h-fit max-h-[400px]">
                    <TableWithButton tableHead={TABLE_HEAD}
                        tableBody={
                            licensesByDirectory.map(
                                (license) => {
                                    if (license.assignedUserId !== "" && hasData) {
                                        return {
                                            itemId: license.licenseId,
                                            tableData: [
                                                license.subscriptionId,
                                                getUserEmailById(license.assignedUserId),
                                                ConvertUnixTimestampToDate(license.validUntilUnix),
                                                license.licenseType,
                                                t("Revoke")],
                                        };
                                    }
                                    else {
                                        return { itemId: "", tableData: [] };
                                    }
                                })
                        } onClickFunction={(param) => onRevokeClick(param)} />
                </div>

                <div className="flex flex-col gap-3">
                    <div className="flex flex-row items-center gap-2">
                        <Typography variant="paragraph" className="uppercase font-semibold text-[16px]">{t("AssignLicense")}</Typography>
                        <InfoComponent text={"AssignLicenseInfo"} />
                    </div>
                    {/* Inside Set Assign license:*/}
                    <div className="flex flex-col 2xl:flex-row gap-5">
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectDirectoryAssignLicense" label={t("SelectDirectory")} className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded" color="orange" error={directoryErrorStatus} onChange={(choice) => onDirectoryChange(choice)} value={directoryRef.current} defaultValue={""}>
                                    {directorySliceData.directoryData.directoryData.map((directory, index) => {
                                        if (directory.role !== AppRoles.Member) {
                                            return (
                                                <Option id={index.toString()} key={directory.directoryId + index} value={directory.directoryId}>{directory.directoryMail}</Option>
                                            );
                                        }
                                        return <div key={index}></div>;
                                    })}
                                </Select>
                            </div>
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectLicenseAssignLicense" label={t("SelectLicense")} className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded" color="orange" disabled={directoryRef.current === ""} error={licenseErrorStatus} onChange={(choice) => onLicenseChange(choice)} value={licenseRef.current !== "" ? licenseRef.current : undefined} defaultValue={""}>
                                    {directoryRef.current !== "" ? licensesStripeData.licensesData.licensesByDirectory.map((license, index) =>
                                        <Option id={index.toString()} key={license.licenseId + index} value={license.licenseId}>{license.subscriptionId}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                        </div>
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectUserAssignLicense" label={t("SelectUser")} className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded" color="orange" disabled={directoryRef.current === ""} error={userErrorStatus} onChange={(choice) => onUserChange(choice)} value={userRef.current !== "" ? userRef.current : undefined} defaultValue={""}>
                                    {directoryRef.current !== "" ? directoryMembers.map((member, index) =>
                                        <Option id={index.toString()} key={member.userId + index} value={member.userId}>{member.email}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                            <div>
                                <Button id="assignBtn" onClick={onAssignClick} color="deep-orange" className={"bg-#FF8136 float-right shadow-none hover:bg-#FF6104 hover:shadow-none "}>{t("Assign")}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3 h-fit max-h-[300px]">
                    <Typography variant="paragraph" className="uppercase text-[16px] font-semibold">{t("YourLicenses")}</Typography>
                    {/* Inside Directory Members:*/}
                    <TableWithStripedRows tableHead={TABLE_HEAD_2} tableBody={licensesByUser.map(
                        (license) => {
                            if (license.licenseId !== "") {
                                return {
                                    itemId: license.licenseId,
                                    tableData: [license.subscriptionId,
                                    getDirectoryEmailById(license.directoryId),
                                    ConvertUnixTimestampToDate(license.validUntilUnix),
                                    license.licenseType],
                                };
                            }
                            else {
                                return { itemId: "", tableData: [] };
                            }
                        })} />
                </div>
            </div>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    )
}